import styles from './style.module.scss';

interface SeparatorProp {
    title?: string;
    variant?: "thick" | "thin";
}

export default function Separator(prop: SeparatorProp) {

    const style = prop.variant === "thin" ? "line-thin" : "line";

    if(prop.title) {
        return (
            <div className={styles['container']}>
                <div className={styles[style]}/>
                <h1 className={styles['title']}>{prop.title}</h1>
                <div className={styles[style]}/>
            </div>
        );
    }
    
    return (
        <div className={styles[style]}/>
    );
}