import { useEffect, useState } from "react";
import styles from './style.module.scss';

interface ImageCarouselProp {
    imageSources: string[];
    intervalDuration?: number;
    className?: string;
}

export default function ImageCarousel(prop: ImageCarouselProp) {

    if(prop.imageSources.length < 2) {
        throw new Error("Please add at least two pictures to the ImageCarousel component!");
    }

    const duration = prop.intervalDuration ? prop.intervalDuration : 5000;
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setTimeout(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % prop.imageSources.length);
        }, duration);

        return () => clearTimeout(interval);
    }, [currentImage, prop.imageSources, duration]);

    return(
        <img 
            src={prop.imageSources[currentImage]}
            alt={`ImageCarousel ${currentImage + 1}: ${prop.imageSources[currentImage]}`} 
            className={`${styles['image']} ${prop.className}`}
        />
    );
}
