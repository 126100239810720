import Footer from '../../components/footer';
import Header from '../../components/header';
import ContactSection from './sections/contact-section';
import MainSection from './sections/main-section';
// import PriceCalculatorSection from './sections/price-calculator-section';
import ServiceSection from './sections/service-section';
import styles from './style.module.scss';

export default function LandingPage() {
    return (
        <div className={styles['page-wrapper']}>
            <Header id="header" className={styles['container']}/>

            <MainSection id="main-section" className={styles['container']}/>
            <ServiceSection id="service-section" className={styles['container']}/>
            {/* <PriceCalculatorSection id="price-calculator-section" className={styles['container']}/> */}
            <ContactSection id="contact-section" className={styles['container']}/>

            <Footer/>
        </div> 
    );
}
