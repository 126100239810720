import styles from './style.module.scss';

export default function Logo() {
    return(
        <div className={styles["logo-container"]}>
            <img src="img/logo.png" alt="Malermester Michael Müller Logo" height="74px"/>
            <div className={styles['company-name-wrapper']}>
                <h1 className={styles['company-name-first-part']}>Malermester</h1>
                <h1 className={styles["company-name-second-part"]}>Michael Müller</h1>
            </div>
        </div>
    );
}
