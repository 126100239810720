import styles from './style.module.scss';
import Button from '../button';
import { Link } from 'react-router-dom';
import { scrollToSection } from '../../util/help-functions';
import Logo from '../logo';

interface HeaderProp {
    id?: string;
    className?: string;
}

const Header = (prop: HeaderProp) => {
    return (
        <header id={prop.id} className={prop.className}>
            <div className={styles["header-container"]}>
        
                <Link to="/" className={styles["link"]}>
                    <Logo/>
                </Link>

                <nav className={styles['menu-container']}>
                    <Button text={"Tjenester"} variant='primary-outline' onClick={() => scrollToSection("service-section")}/>
                    {/* <Button text={"Priskalkulator"} variant='primary-outline' onClick={() => scrollToSection("price-calculator-section")}/> */}
                    <Button text={"Kontakt"} variant='primary' onClick={() => scrollToSection("contact-section")}/>
                </nav>

            </div>
        </header>
    );
};
  
export default Header;
