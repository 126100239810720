import styles from "./style.module.scss";

interface ContactInfoProp {
    variant: "phone" | "mail" | "location";
    classNames?: string;
}

export default function ContactInfo(prop:ContactInfoProp) {
    let symbol, href, text, textTwo: string;

    if(prop.variant === "phone") {
        symbol = "call";
        href = "tel:46552961";
        text = "465 52 961";
        textTwo = "";
    }
    else if(prop.variant === "mail") {
        symbol = "mail";
        href = "mailto:malermester.mueller@gmail.com";
        text = "malermester.mueller@gmail.com";
        textTwo = "";
    }
    else {
        symbol = "location_on";
        text = "Feda (Kvinesdal)";
        textTwo = "Kvås (Lyngdal)";
    }
 
    return(
        <div className={`${prop.classNames} ${styles['container']}`}>
            <a href={href}><span className={`${styles['symbol']} material-symbols-outlined`}>{symbol}</span></a>
            <a className={styles['link']} href={href}>{text}<br/>{textTwo}</a>
        </div>
    );
}
