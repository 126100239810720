import styles from './style.module.scss';

interface ButtonProp {
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    variant?: "primary" | "primary-outline" | "white-outline" | "primary-borderless";
    rounded?: boolean
}

function computeButtonStyle(prop: ButtonProp): string {
    let classNames = styles['btn'];

    classNames +=
        prop.variant === 'primary-outline'
            ? ` ${styles['primary-outline']}`: prop.variant === 'white-outline'
            ? ` ${styles['white-outline']}` : ` ${styles.primary}`;

    classNames += (prop.rounded) ? ` ${styles['rounded']}` : "";

    return classNames;
}

export default function Button(prop: ButtonProp) {
    const classNames = computeButtonStyle(prop);
    return (
        <button 
            onClick={prop.onClick}
            className={classNames}
        >{prop.text}</button>
    );
}