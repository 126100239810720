
export const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if(section) {
        section.scrollIntoView({behavior: 'smooth'});
    }
}


// ---------------------------------------------------------

const outsidePaintingPriceOneColor = 364.10;
const outsidePaintingPriceMultiColor = 384.10;
const taxPercentage = 0.25;

export const computeOutsidePaintingPrice = (quadratmeters:number, isMultiColor:boolean):number => {
    quadratmeters = quadratmeters > 0 ? quadratmeters : 0;
    return isMultiColor 
        ? outsidePaintingPriceMultiColor * quadratmeters 
        : outsidePaintingPriceOneColor * quadratmeters;
}

export const computeTax = (amount:number):number => {
    return amount * taxPercentage;
}

export const formatToCurrency = (amount:number):string => {
    return amount.toLocaleString("nb-NO", {
        style: "currency",
        currency: "NOK",
        minimumFractionDigits: 2,
        currencyDisplay: "code"
    }).replace("NOK", "").trim().concat(" NOK")
}
