// import Separator from "../separator";
import styles from "./style.module.scss";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <div style={{height: "5rem"}}/>
            <div className={styles.column}>
                <div className={styles.copyright}>
                    <span className="material-symbols-outlined">copyright</span>
                    <span>{currentYear} - Malermester Michael Müller</span>
                </div>
                <div>
                    <span>OrgNum: <a href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=931897756">931897756</a></span>
                </div>
                <div style={{height: "1rem"}}/>
                <span><strong>Webdesign:</strong></span>
                <span>Katja Müller & Florian S. Müller</span>
                <div style={{height: "1rem"}}/>
                <span><strong>Webutvikling:</strong></span>
                <span>Elina Tang Wa Pedersen & Florian S. Müller</span>
            </div>
            <div style={{height: "4rem"}}/>
        </footer>
    );
}
