import Button from '../../../../components/button';
import ImageCarousel from '../../../../components/carousel';
import { scrollToSection } from '../../../../util/help-functions';
import styles from './style.module.scss';

interface MainSectionProp {
    id: string;
    className: string;
}


export default function MainSection(prop: MainSectionProp) {

    const mainSectionImageCarouselSources = [
        "img/carousel_2.jpg",
        "img/carousel_1.jpg",
        "img/carousel_7.jpg"
    ];

    return(
        <section id={prop.id} className={`${prop.className} ${styles['main-section']}`}>
            <div className={styles['left-column']}>
                <div>
                    <h1>Din malerbedrift!</h1>
                    <h2>Verdibevaring, service og kvalitet. Den profesjonelle gjennomføringen av kundens behov er et selvfølge. Vi representerer mer enn bare farge på veggen. </h2>
                    <h2>Bli gjerne kjent med vår fast kvadratmeterpris for utvendig maling!</h2>
                </div>
                <Button text={"Kontakt"} onClick={() => scrollToSection("contact-section")}/>
            </div>

            <div className={styles['right-column']}>
                <ImageCarousel 
                    imageSources={mainSectionImageCarouselSources} 
                    className={styles['image-carousel']}
                />
            </div> 
        </section>
    );
}
