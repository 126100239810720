import ContactInfo from '../../../../components/contact-info';
import Separator from '../../../../components/separator';
import styles from './style.module.scss';

interface ContactSectionProp {
    id: string;
    className: string;
}

export default function ContactSection(prop: ContactSectionProp) {
    return(
        <section id={prop.id} className={`${prop.className} ${styles['contact-section']}`}>
            <h2 className={styles['title']}>Har du et prosjekt i tankene? </h2>

            <div className={styles['first-row']}>
                <p className={styles['paragraph']}>
                    Da er det bare å ta kontakt. Du kan ringe eller skrive oss en mail.<br/>
                    Vi tar oppdrag i Flekkefjord, Kvinesdal, Lyngdal, Farsund og hele Lister regionen!
                </p>
            </div>

            <div className={styles['second-row']}>
                <ContactInfo variant='phone'/>
                <ContactInfo variant='mail'/>
                <ContactInfo variant='location'/>
            </div>

            <Separator />
        </section>
    );
}
