import Button from '../../../../components/button';
import ImageCarousel from '../../../../components/carousel';
import { scrollToSection } from '../../../../util/help-functions';
import styles from './style.module.scss';

interface ServiceSectionProp {
    id: string;
    className: string;
}

export default function ServiceSection(prop: ServiceSectionProp) {

    const mainSectionImageCarouselSources = [
        "img/carousel_5.jpg",
        "img/carousel_4.jpg",
        "img/carousel_6.jpg"
    ];

    return(
        <section id={prop.id} className={`${prop.className} ${styles['service-section']}`}>
            {/* <Separator title='Tjenester' /> */}
            
            <div className={styles['first-row']}>
                <div className={styles['left-column']}>
                    <ImageCarousel 
                        imageSources={mainSectionImageCarouselSources} 
                        className={styles['image-carousel']}
                        intervalDuration={4320}
                    />
                </div>
                <div className={styles['right-column']}>
                    <h2>Hva trenger du hjelp med?</h2>
                    <ul>
                        <li>Maling innvendig & utvendig</li>
                        <li>Sparkling</li>
                        <li>Tapetsering</li>
                        <li>Dekorativ overflatebehandling</li>
                        <li>Montasje av spon- og gipsplater</li>
                        <li>Betongrehabilitering</li>
                        <li>Murerpuss (Mineralsk, Organisk, Silikonhart)</li>
                        <li>Isolasjon</li>
                        <li>Lydisolasjon og akustikk-systemer</li>
                    </ul>
                    <div>
                        {/* <Button text={"Priskalkulator"} variant='primary-outline' onClick={() => scrollToSection("price-calculator-section")}/> */}
                        <Button text={"Kontakt"} onClick={() => scrollToSection("contact-section")}/>
                    </div>
                </div>
            </div>
            
        </section>
    );
}
